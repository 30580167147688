
.cmfa-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: larger;
    font-weight: bold;
}
.cmfa-content-div {
    min-width: 300px;
    min-height: 100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.mfa-spinner {
    padding: 8px;
}
.text-note {
    padding: 4px;
    color: grey;
    font-size: small;
}

.cmfa-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
