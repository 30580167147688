
/* Root */
.caod-root {
    padding: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;
}

/* Close dialog button row */
.header-row {
    flex-grow: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
/* Title text */
.title {
    flex-grow: 1;
    font-size: x-large;
}
.header-side-div {
    flex-grow: 0;
    width: 50px;
}


/* Content */
.caod-content-div {
    flex-grow: 1;
    min-width: 450px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Confirm selection page */
.confirm-page-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
}
.action-btn {
    width: 100px;
    font-size: 16px;
}

/* Async op page */
.okay-btn {}
