
.dlt-root {}

.divider {
    width: 100%;
}

.license-note-div {
    width: 100%;
    text-align: center;
}
.license-note {
    color: grey;
    font-size: medium;
    margin-top: 16px;
    margin-bottom: 16px;
}
.license-note-spacer {
    height: 16px;
}

.center-note {
    width: 100%;
    text-align: center;
}

.small-note {
    width: 100%;
    text-align: center;
    margin-top: 3px;
    font-size: small;
    color: grey;
}

.center-row {
    width: 100%;
    text-align: center;
}

.edit-license-div {
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.manual-action-btn {
    margin: 8px;
}
.edit-license-section {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8px;
}

.subscription-div {
    width: 90%;
    margin: auto;
}
