
.settings-root {
    width: 90%;
    margin: auto;
}

.settings-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.settings-sub-content {
    flex-grow: 0;
    flex-shrink: 1;
    margin: 16px;
    min-width: 300px;
}

.prof-tf {
    width: 300px;
}

.last-email-note {
    color: grey;
    font-size: small;
}
