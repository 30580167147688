
.sub-tile-paper {
    width: 100%;
    margin: 8px;
    padding: 12px;
}

.sub-tile-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.sub-header-row {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.sub-header-icon-col {
    margin-left: 6px;
    margin-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.sub-header-name-col {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.sub-title {
    font-size: large;
}
.sub-subtitle {
    color: grey;
    font-size: small;
}


.sub-data-row {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.invoice-pdf-link {
    font-size: medium;
    padding: 3px;
}

.divider {
    width: 100%;
}

.sub-org-div {
    width: 100%;
    margin: auto;
}
.sub-devices-div {
    width: 100%;
    margin: auto;
}