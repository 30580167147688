
.udr-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.udr-label {
    font-size: 100%;
}
.udr-value {
    font-size: 100%;
}
