
/* Item list */
.item-list-root {
    width: 100%;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

/* Action log item */
.log-item-root {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.log-item-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.log-item-icon-col {
    flex-grow: 0;
}
.log-item-content-col {
    flex-grow: 1;
    text-align: start;

}
.log-content-text {
    font-size: medium;
}
.log-footer-text {
    font-size: small;
}


/* Recent alerts */
.ra-list-root {
    width: 100%;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}