
.content-start {
    margin: auto;
    width: 90%;
    min-width: 300px;
    max-width: 800px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.content-center {
    margin: auto;
    width: 90%;
    min-width: 300px;
    max-width: 800px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}


.card-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
}

.notes-area {
    min-width: 80%;
    max-width: 80%;
}
