
.devices-root {
    width: 90%;
    margin: auto;
}

.devices-subheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ds-desc-col {
    flex-grow: 1;
}
