
.search-toolbar {
    padding: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.search-sort {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: auto;
    justify-content: flex-end;
    align-items: center;
    margin: 8px 8px 8px 8px;
}
.search-filter {
    flex-grow: 1;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: auto;
    justify-content: flex-start;
    align-items: center;
    margin: 8px 8px 8px 8px;
}

#search_input {

}
.sold-filter-div {
    justify-self: flex-end;
    flex-grow: 0;
}
.sf-sel {
    color: var(--MC_MAIN);
    font-weight: bold;
}