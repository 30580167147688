.App {
  text-align: center;
}

.splash-div {
  background: var(--MC_MAIN);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.splash-logo {
  height: 30vh;
  pointer-events: none;
}
.splash-text {
  font-size: xx-large;
  color: white;
}
