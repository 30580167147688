
.table-div {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.id-div {
    max-width: 100px;
    display: block;
}
.id-span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.name-div {
    display: block;
    max-width: 200px;
}
.name-span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.org-status-span {
    pointer-events: none;
}

.none {}
.fine {
    color: green;
}
.greentext {
    color: green;
}
.soon {
    color: orange;
}
.overdue {
    color: red;
}

