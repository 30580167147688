/* Item Row */
.item-root {
    width: 90%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: auto;
    margin-right: auto;
}
.item-row-root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

/* Icon */
.item-icon-col {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

/* Name/info display */
.item-name-col {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 12px;
    min-width: 0px;  /*To allow shrinking of nowrap text lines*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.item-title {
    text-align: start;
    font-size: large;
    flex-shrink: 1;
    min-width: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.item-id {
    text-align: start;
    font-size: small;
    color: grey;

    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}




/* Action buttons */
.item-action-col {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}





.link-margin {
    margin-left: 6px;
    margin-right: 6px;
}
.skel-text {
    flex-grow: 1;
    flex-shrink: 0;
    width: 200px;
}






/* Activity log */
.log-content-col {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.log-content-desc {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    text-align: start;
    padding-bottom: 8px;
}
.log-content-footer {
    width: 100%;
    display: block;
    text-align: start;
    word-wrap: break-word;
}

/* Recent alert item */
.ra-root {
    margin: 8px;
    padding: 12px;
}
.ra-row-col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}


.ra-header-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.ra-icon {
    justify-self: center;
}
.ra-header-name-col {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.ra-title {
    font-weight: bold;
    font-size: larger;
}
.ra-subtitle {
    color: grey;
    font-size: small;
}

.ra-contact-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.ra-bad {
    color: red;
}
.ra-good {
    color: green;
}

.ra-message-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ra-footer-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.ra-ts {
    font-size: small;
    color: grey;
}

