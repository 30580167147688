
.logs-root {
    width: 90%;
    margin: auto;
}

.logs-filter-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

/* This isn't working */
.filter-chip {
}

.logs-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
