.admin-notes-area {
    margin: auto;
    margin-top: 6px;
    min-width: 80%;
    max-width: 80%;
}

.center-row {
    width: 100%;
    text-align: center;
}
