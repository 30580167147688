
.view-org-root {
    width: 90%;
    margin: auto;
}


.content-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.tablistbox {
    background: whitesmoke;
    border-radius: 12px;
    max-width: 100%;
}

.content-start-note {
    align-self: center;
    color: gray;
}

.btn-note {
    padding: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
}
.links {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}
.link {
    font-size: 18px;
    width: 200px;
}