
.overview-root {
    width: 90%;
    margin: auto;
}
.overview-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

.overview-summary-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}





.paper-content {
    width: 80%;
    min-width: 250px;
    max-width: 500px;
    min-height: 150px;
    padding: 12px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.subtitle {
    font-size: 125%;
    font-weight: bold;
    width: 100%;
    text-align: center;
}
.subfooter {
    padding-bottom: 4px;
    font-size: 75%;
    width: 100%;
    text-align: center;
    color: grey;
}
.subdetail-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.device-support-section {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

