
.dinfotab-root {

}

.divider {
    width: 100%;
}

.center-row {
    width: 100%;
    text-align: center;
}

.jas-fee-note {
    width: 100%;
    text-align: start;
    color: grey;
    font-size: small;
}

.notes-area {
    margin: auto;
    margin-top: 6px;
    min-width: 80%;
    max-width: 80%;
}