
.view-device-root {
    width: 90%;
    margin: auto;
}

.content-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.tablistbox {
    background: whitesmoke;
    border-radius: 12px;
    max-width: 100%;
}

.btn-note {
    padding: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
}
