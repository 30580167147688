/* Content start from ViewPaper.css */
.issue-info-root {
    width: 80%;
    margin: auto;
    min-width: 300px;
    max-width: 800px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.ii-divider {
    width: 100%;
    background: lightgrey;
    height: 1px;
    margin-top: 12px;
    margin-bottom: 12px;
}

/* Issue info subsection */
.ii-subsection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.ii-subtitle {
    width: 100%;
    text-align: start;
    font-size: 22px;
    font-weight: normal;
}

/* Content */
.issue-detail {
    font-size: 16px;
}

.issue-user-info {
    width: 100%;
}

.ii-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

