
.view-user-root {
    width: 90%;
    margin: auto;
}

.content-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.tablistbox {
    background: whitesmoke;
    border-radius: 12px;
    max-width: 100%;
}


.btn-note {
    padding: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
}

.admin-notes-area {
    margin: auto;
    margin-top: 6px;
    min-width: 80%;
    max-width: 80%;
}

.center-row {
    width: 100%;
    text-align: center;
}
