.pgsil-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}
.pgsil-ctl {
    flex-grow: 0;
}
.pgsil-content {
    min-width: 400px;
    flex-grow: 1;
    flex-shrink: 1;
    justify-self: stretch;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.pgsil-footer {
    flex-grow: 0;
    align-self: center;
}