
.issue-tile-paper {
    margin: 8px;
    padding: 12px;
    cursor: pointer;
}
.issue-tile-paper:hover {
    background: whitesmoke;
}
.issue-tile-paper:hover .issue-tile-root .content-col .content-header .subj-txt {
    text-decoration: underline;
}

.issue-tile-root {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.icon-col {
    flex-grow: 0;
    width: 90px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.icon {}
.status {
    /*pointer-events: none;*/
    margin-top: 8px;
    color: grey;
    font-size: 14px;
    font-weight: bold;
}
.status-open {

}
.status-in-progress {

}
.status-closed {

}

.content-col {
    flex-grow: 1;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.content-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.content-message {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.content-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
.subj-txt {
    font-size: 24px;
    color: var(--MC_MAIN);
    text-decoration-color: var(--MC_MAIN);
}
.help-txt {
    font-size: 10px;
    color: grey;
}

.footer-txt {
    color: grey;
    font-size: 12px;
}
