
.table-div {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.name-span {

    max-width: 150px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.id-span {
    min-width: 100px;
    display: block;
}

.status-span {
    pointer-events: none;
}
.last-post-span {
    min-width: 100px;
    display: block;
}


.dlink {
    color: red;
}