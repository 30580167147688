.table-div {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.id-span {
    max-width: 50px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.email-span {
    min-width: 150px;
    display: block;
}
.name-span {
    min-width: 100px;
    display: block;
}
.phone-span {
    min-width: 100px;
    display: block;
}
.status-span {
    pointer-events: none;
}
.last-activity-span {
    min-width: 100px;
    display: block;
}

